import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-url-frontend',
  templateUrl: './url-frontend.component.html',
  styleUrls: ['./url-frontend.component.css']
})
export class UrlFrontendComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
