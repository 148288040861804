import { Component } from '@angular/core';

import { HttpModule } from '@angular/http';
import {Injectable, Inject} from '@angular/core';
import {Http, Response, Headers, RequestOptions} from '@angular/http';
import { RouterModule, Routes, Router } from '@angular/router';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, NgForm} from '@angular/forms';
import { NgModule } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';

import { AuthService } from '../auth.service';

@Component({
  selector: 'url',
  templateUrl: './url.component.html',
  styleUrls: ['./url.component.css']
})


@Injectable()
export class UrlComponent {
	url = '';
	shortUrl = '';
  buttonclick = '0';
  subdomain = '';
  error = '';
  isCopied1: boolean;

	constructor(@Inject(Http) private http: Http, @Inject(Router) private router:Router, private activatedRoute: ActivatedRoute, private authService: AuthService, private _clipboardService: ClipboardService) {

		let options = new RequestOptions();
        options.headers = new Headers();
        options.headers.append('Content-Type', 'application/json');
        options.headers.append('Accept', 'application/json');

        let link_Id = '';
        this.activatedRoute.params.subscribe((params) => {
        	console.log(params);
          	link_Id = params['link_Id'];
      	});

        if(link_Id != '' && link_Id != undefined) { 
        	window.open(this.subdomain+'/'+link_Id,"_blank");
	      }


        // test stripe webhook data response
        /*this.http.get(this.subdomain+'/stripewebhook' ,options)
        .subscribe(response => {
          console.log(response, response['_body']); 
        }, error => {
            console.log(error.json());
        });*/
	}

  ngOnInit() {
      this._clipboardService.copyResponse$.subscribe(re => {
          if (re.isSuccess) {
              alert('copy success!');
          }
      });
  }
  callServiceToCopy() {
      this._clipboardService.copy('This is copy thru service copyFromContent directly');
  }

  onCopyFailure() {
      alert('copy fail!');
  }

  logOut() {
    this.authService.logOut();
  }

  openShortUrl(shortCode) {
    window.open(this.subdomain+'/'+shortCode,"_blank");

    this.url = '';
    this.shortUrl = '';
    this.buttonclick = '0';
  }

	getShortUrl() {
    this.buttonclick = '1';
    this.error = '';
    this.shortUrl = '';

    if(this.url.match(/(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) == null) {
      this.error = "Please enter the correct url format.";
    } else {
      let options = new RequestOptions();
      options.headers = new Headers();
      options.headers.append('Content-Type', 'application/json');
      options.headers.append('Accept', 'application/json');

      let data = {
        "url": this.url,
        "sub": this.subdomain
      }

      this.http.post(this.subdomain+'/createlink', data ,options)
        .subscribe(response => {
          console.log(response, response['_body']); 
          if(response['_body'] == "error") {
            this.error = "Please enter the correct url format.";
          } else {
            this.url = '';
            this.shortUrl = this.subdomain + '/' + response['_body'];
          }
      }, error => {
          console.log(error.json());
      });
    }
	}
}
