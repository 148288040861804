import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { UrlComponent } from './url/url.component';

import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
	{ path: '', component: UrlComponent, canActivate: [MsalGuard]},
	{ path: ':link_Id', component: UrlComponent, canActivate: [MsalGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
