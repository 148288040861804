<div class="overlay" *ngIf="shortUrl == '' && error == ''  && buttonclick == '1'"></div>
<div class="container">
  <div class="spinner-border" style="width: 3rem; height: 3rem; position: absolute; top: 25vh; left: 50%; z-index:9;" role="status" *ngIf="shortUrl == '' && error == '' && buttonclick == '1' ">
    <span class="sr-only">Loading...</span>
  </div>
  <div class="row justify-content-md-center">
    <div class="col-md-10 col-md-offset-1 boxStyle">
      <button class="btn btn-primary" style="float:right;" (click)="logOut()">Logout</button>
      <h1 class="header">Url Shortner</h1>
      <form class="" #urlForm="ngForm">
        <div class="alert alert-danger" role="alert" *ngIf="error != ''">
          {{error}}
        </div>
        <div class="row form-group">
          <div class="col-md-12">
            <label>Original url</label>
            <input class="form-control" name="url" placeholder="Original URL" type="text"  [(ngModel)]="url">
          </div>
        </div>
        <div class="row form-group">
          <div class="col-md-12">
            <label>Subdomain</label>
            <select class="form-control" name="subdomain" [(ngModel)]="subdomain">
              <option value="">Select subdomain</option>
              <option value="https://meet.meridianuniversity.edu">meet.meridianuniversity.edu</option>
              <option value="https://link.meridianuniversity.edu">link.meridianuniversity.edu</option>
              <option value="https://m.university">m.university</option>
              <option value="https://go.meridianu.edu">go.meridianu.edu</option>
            </select>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-md-12">
            <button class="btn btn-primary" (click)="getShortUrl()" [disabled]="url == '' || subdomain == ''">Get short url</button>
          </div>
        </div>
        <div class="text-success" *ngIf="shortUrl != ''">
          Short URL Code :  <strong>{{shortUrl}}</strong> &nbsp;&nbsp;
          <button
            class="btn btn-sm btn-primary"
            type="button"
            ngxClipboard
            [cbContent]="shortUrl"
            (cbOnSuccess)="isCopied1 = true"
            (cbOnError)="onCopyFailure()"
            [class.btn-success]="isCopied1"
          >
            copy
          </button>
           
          <a class="pull-right text-primary" href='{{shortUrl}}' target="_blank">Test short url</a>
        </div>

        <!-- <div class="row form-group" *ngIf="shortUrl != ''">
          <div class="col-md-12">
            <button class="btn btn-primary" (click)="openShortUrl(shortUrl)">Test short url</button>
          </div>
        </div> -->

      </form>
    </div>
  </div>
</div>
