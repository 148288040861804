import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { UrlFrontendComponent } from './url-frontend/url-frontend.component';
import { UrlComponent } from './url/url.component';

import { AuthService } from './auth.service';

import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";

import { ClipboardModule } from 'ngx-clipboard';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent,
    UrlComponent,
    UrlFrontendComponent
  ],
  imports: [
  	FormsModule,
    BrowserModule,
    HttpModule,
    ClipboardModule,
    AppRoutingModule,
    MsalModule.forRoot({
      auth: {
        clientId: '6b9d9a08-41f3-45e5-98ad-b188599ba443', // This is your client ID
        authority: 'https://login.microsoftonline.com/df296a22-5ad0-4a7c-b894-01ddd1bd3e10', // This is your tenant ID
        redirectUri: 'https://urlshortener.meridianuniversity.edu/', // This is your redirect URI
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true, // Set to true for Internet Explorer 11
      },
    }, {
      popUp: false,
      consentScopes: [
        'user.read',
        'openid',
        'profile',
      ],
      unprotectedResources: ["https://www.microsoft.com/en-us/"],
      protectedResourceMap: [['https://graph.microsoft.com/v1.0/me', ['user.read']]],
      extraQueryParameters: {}
    })
  ],
  providers: [AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }
