import { Component, OnInit  } from '@angular/core';

import { HttpModule } from '@angular/http';
import {Injectable, Inject} from '@angular/core';
import {Http, Response, Headers, RequestOptions} from '@angular/http';
import { RouterModule, Routes, Router } from '@angular/router';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { AuthService } from './auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


@Injectable()
export class AppComponent implements OnInit{
	title = 'SecureApp';

	constructor(private authService: AuthService) { }

	ngOnInit() {
		this.authService.initializeAuth();
	}

	login() {
		this.authService.logIn();
	}

	logOut() {
		this.authService.logOut();
	}
}
